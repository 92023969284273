<template>
  <el-container>
    <!-- 侧边栏 -->
    <el-aside width="200px">
      <el-menu default-active="1" class="el-menu-vertical-demo">
        <el-menu-item index="1">
          <i class="el-icon-s-home"></i>
          <span slot="title">系统首页</span>
        </el-menu-item>
        <el-menu-item index="2">
          <i class="el-icon-s-management"></i>
          <span slot="title">信息管理</span>
        </el-menu-item>
        <el-menu-item index="3">
          <i class="el-icon-user"></i>
          <span slot="title">用户信息</span>
        </el-menu-item>
        <el-menu-item index="4">
          <i class="el-icon-setting"></i>
          <span slot="title">管理员</span>
        </el-menu-item>
      </el-menu>
    </el-aside>

    <!-- 主内容区域 -->
    <el-container>
      <!-- 顶部导航 -->
      <el-header>
        <el-row>
          <el-col :span="20">
            <span>早安，蜂哥，祝你开心每一天！</span>
          </el-col>
          <el-col :span="4" style="text-align: right;">
            <el-avatar size="medium" icon="el-icon-user"></el-avatar>
          </el-col>
        </el-row>
      </el-header>

      <!-- 主要内容 -->
      <el-main>
        <el-card style="width: 50%">
          <div slot="header" class="clearfix">
            <span>HAHA</span>
          </div>
          <div>
            2024毕设正式开始了！青哥哥带你手把手敲出来！
          </div>
          <!-- 按钮组 -->
          <el-button type="success">按钮</el-button>
          <el-button type="warning">按钮</el-button>
          <el-button type="danger">按钮</el-button>
          <el-button type="primary">按钮</el-button>
        </el-card>


        <el-card style="width: 50%">
        <div>
          <el-table :data="users">
            <el-table-column label="ID" prop="id"></el-table-column>
            <el-table-column label="用户名" prop="username"></el-table-column>
            <el-table-column label="姓名" prop="name"></el-table-column>
            <el-table-column label="地址" prop="address"></el-table-column>
            </el-table>
        </div>
        </el-card>

        <el-button type="info"@click="getInfo">信息按钮</el-button>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
 import axios from "axios";
import request from "@/utils/request";
export default {
  name: 'App',
  data() {
    return {
      users: [
        {
          id:0,
          username:"haha",
          name:"HAHA",
          address:"shandong"
        }
      ]
    }
  },

  methods: {
    getInfo() {
      axios.get('http://localhost/mycode/test/findAll.php').then(res => {
            console.log(res)
            this.users = res.data.data
        console.log(res.data.data)
          }
      )}
    }


  //   mounted() {

// request.get('/user/findAll').then(res => {
//   console.log(res.data)
//   this.users = res.data
// })
//   },
  // axios.get('http://localhost:9090/user/findAll').then(res => {
  //   console.log(res)
  //   this.users = res
  // })
  //},


}

</script>

<style scoped>
.el-header {
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
}
.el-main {
  padding: 20px;
}
</style>
